<template>
  <div class="container">
    <div class="handle-box">
      <el-input
        v-model="query.studentId"
        placeholder="Student No."
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-input
        v-model="query.studentName"
        placeholder="Student Name"
        class="handle-input mr10"
        @keyup.enter="handleSearch"
        :clearable="true"
      ></el-input>
      <el-button type="primary" @click="handleSearch">
        <el-icon>
          <search />
        </el-icon>
        Search
      </el-button>
      <div style="float: right">
        <el-button @click="handleDeleteInBatch">
          <el-icon>
            <delete />
          </el-icon>
          Remove selected students
        </el-button>
        <el-button type="primary" @click="openImportStudentDialog">
          <el-icon>
            <plus />
          </el-icon>
          Import Students
        </el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      class="table"
      ref="multipleTable"
      row-key="studentId"
      header-cell-class-name="table-header"
      @sort-change="changeSort"
      :default-sort="{ prop: 'StudentName', order: 'ASC' }"
      @selection-change="handleStudentSelectionChange"
    >
      <el-table-column
        type="selection"
        :width="this.$widthRatio * 55 + 'px'"
        :reserve-selection="true"
      />
      <el-table-column
        prop="studentId"
        label="Student No."
        :width="this.$widthRatio * 120 + 'px'"
      ></el-table-column>
      <el-table-column prop="studentName" label="Student Name"></el-table-column>
      <el-table-column
        prop="createdTime"
        sortable="true"
        :formatter="$tableCellFormatter.formatDate"
        label="Enroll Time"
        :width="this.$widthRatio * 120 + 'px'"
      ></el-table-column>
      <el-table-column
        label="Operation"
        align="center"
        :width="this.$widthRatio * 140 + 'px'"
      >
        <template #default="scope">
          <el-button type="text" @click="printReceipt(scope.$index, scope.row)">
            <el-icon>
              <printer />
            </el-icon>
            Receipt
          </el-button>
          <el-button
            type="text"
            class="red"
            @click="handleDelete(scope.$index, scope.row)"
          >
            <el-icon>
              <delete />
            </el-icon>
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="itemTotal"
        :page-count="pageTotal"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
  <el-dialog :title="dialogTitle" v-model="showStudentSelectionDialog" width="80%">
    <students-selection
      :initData="{ parent: this, confirmFunction: handleImportStudents }"
    />
  </el-dialog>
</template>

<script>
import { getData, postData, putData } from "../../service/api";
import StudentsSelection from "./StudentsSelection.vue";

export default {
  name: "manageStudentsInClassRoom",
  components: { StudentsSelection },
  props: {
    classRoom: Object,
  },
  data() {
    return {
      controllerUrl: "/classRoom",
      query: {
        roomId: null,
        studentId: null,
        studentName: null,
        orderBy: "StudentName",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 50,
      },
      options: {
        gradeList: [],
      },
      currentClassRoom: null,
      showStudentSelectionDialog: false,
      studentList: [],
      tableData: [],
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  watch: {
    classRoom(newValue) {
      if (newValue) {
        if (newValue !== this.currentClassRoom) {
          this.initAndLoadData(newValue);
        }
      }
    },
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.initAndLoadData(this.classRoom);
  },
  methods: {
    initAndLoadData(classRoom) {
      console.log("initAndLoadData", this.currentClassRoom, classRoom);
      this.currentClassRoom = classRoom;
      if (this.currentClassRoom) {
        this.query.roomId = this.currentClassRoom.roomId;
        this.loadData();
      }
    },
    loadData() {
      getData(
        `${this.controllerUrl}/getStudentsInClassRoom/${this.query.roomId}`,
        this.query
      ).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    loadClassRoom() {
      getData(`${this.controllerUrl}/${this.query.roomId}`).then((res) => {
        let resData = res.result;
        this.currentClassRoom.studentCount = resData.studentCount;
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    openImportStudentDialog() {
      this.showStudentSelectionDialog = true;
    },
    handleDelete(index, row) {
      this.studentList.push(row);
      this.handleDeleteInBatch();
    },
    handleDeleteInBatch() {
      if (this.studentList.length == 0) {
        return;
      }
      // twice to confirm to delete
      this.$confirm("Are you sure to remove the students", "Prompt", {
        type: "warning",
      })
        .then(() => {
          let studentIdList = [];
          this.studentList.forEach((r) => {
            studentIdList.push(r.studentId);
          });
          putData(
            `${this.controllerUrl}/removeStudentsFromClassRoom/`,
            this.query.roomId,
            studentIdList
          ).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Students removed successfully");
              this.loadClassRoom();
              this.loadData();
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => {});
    },
    async handleImportStudents(studentList) {
      try {
        let studentIdList = [];
        if (studentList && studentList.length > 0) {
          for (let i = 0; i < studentList.length; i++) {
            studentIdList.push(studentList[i].studentId);
          }
        }
        await this.$confirm(
          `Are you sure to import the students: ${studentIdList}`,
          "Prompt",
          {
            type: "warning",
          }
        );

        let res = await postData(
          `${this.controllerUrl}/addStudentsIntoClassRoom/${this.query.roomId}`,
          studentIdList
        );
        console.log("ManageStudentsInClassRoom.handleImportStudents", studentIdList, res);

        if (res.result && res.code === "200") {
          this.$message.success("Import students successfully");
          this.showStudentSelectionDialog = false;
          this.loadClassRoom();
          this.loadData();
        } else {
          this.$message.error("Import failed, error message: " + res.message);
        }

        return res;
      } catch {
        // ignore
      }
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    printReceipt(index, row) {
      let route = this.$router.resolve({ path: "/buildReceiptTemplate" });
      window.open(
        `${route.href}?roomId=${this.query.roomId}&studentId=${row.studentId}`,
        "_blank"
      );
    },
    handleStudentSelectionChange(rows) {
      console.log("handleStudentSelectionChange", rows);
      if (this.tableData && this.tableData.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          let index = this.studentList.indexOf(this.tableData[i]);
          if (index >= 0) {
            this.studentList.splice(index, 1);
          }
        }
      }
      if (rows && rows.length) {
        for (let i = 0; i < rows.length; i++) {
          if (this.studentList.indexOf(rows[i]) >= 0) continue;
          this.studentList.push(rows[i]);
        }
      }
      console.log("handleStudentSelectionChange", this.studentList);
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
